import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import apiRequests from "../../services/config";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Loader from "../../components/loader/Loader";

function UserManualsUi() {
  const [userManualsData, setUserManualsData] = useState([]);
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    apiRequests("/user-manuals").then((res) => {
      setUserManualsData(res.data.data);
      setIsLoader(false);
    });
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <>
      <Header />
      <main id="content" className={"product-wrapper"}>
        <Breadcrumb links={[{ id: 1, title: "User Manuals" }]} />
        <div className="container">
          <div className="row row-gap-5 liste">
            {userManualsData.map((userManual) => (
              <div className={"col-12 item wrapper-product"}>
                <div
                  className={`encart-product wrapperClic encart-product-list
                          `}
                >
                  <Link to={`#`} target="_blanck">
                    <div className="container-img">
                      <i class="bi bi-filetype-pdf pdf-icon"></i>
                    </div>
                  </Link>
                  <div className="container-caption">
                    <div className="caption">
                      <div className="row">
                        <div className="col-12">
                          <p className="title text-start">
                            <Link
                              to={`${window.location.origin}${userManual.file}`}
                              target="_blanck"
                            >
                              {userManual.title}
                            </Link>
                          </p>
                          <div
                            className="desc text-start"
                            dangerouslySetInnerHTML={{
                              __html: userManual.description,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div
              id="show-more"
              className="show-more"
              arial-label="Voir plus d'accessoires"
              style={{ display: "none" }}
            >
              <div className="loader-cycle-disks"></div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default UserManualsUi;
