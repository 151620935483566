import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Pagination } from "swiper/modules";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./DownloadApp.css";
import apiRequests from "../../services/config";
import Loader from "../../components/loader/Loader";
import { Link } from "react-router-dom";
function DownloadApp() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const [appData, setAppData] = useState({});

  const getSettingsPage = async () => {
    await apiRequests("/get-setting").then((res) => {
      const appSliders = JSON.parse(res.data.data.app_sliders);
      setAppData({
        appTitle: res.data.data.app_title,
        appDescription: res.data.data.app_description,
        appFeatures: res.data.data.app_features,
        googlePlay: res.data.data.googleplay_link,
        appStore: res.data.data.appstore_link,
        appLink: res.data.data.apk_path,
        appSiliders: appSliders,
      });

      setIsLoader(false);
    });
  };

  useEffect(() => {
    getSettingsPage();
  }, []);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <>
      <Header />

      <div className="download-main">
        <div itemscope="" itemtype="http://schema.org/Product">
          <div className="container-fluid">
            <Breadcrumb links={[{ id: 3, title: "GO TERRAIN Application" }]} />
            <div className="row align-items-lg-center margin-top-20">
              <div class="col-12 col-lg-6">
                {console.log(appData)}

                <Swiper
                  style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                  }}
                  spaceBetween={10}
                  navigation={true}
                  thumbs={{ swiper: thumbsSwiper }}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="mySwiper2"
                >
                  {appData.appSiliders.map((img) => (
                    <SwiperSlide>
                      <img src={`${window.location.origin}${img.url}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <Swiper
                  onSwiper={setThumbsSwiper}
                  spaceBetween={10}
                  slidesPerView={4}
                  freeMode={true}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Navigation, Thumbs]}
                  className="d-none d-lg-block mySwiper"
                >
                  {appData.appSiliders.map((img) => (
                    <SwiperSlide>
                      <img src={`${window.location.origin}${img.url}`} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              <div className="col-12 col-lg-6">
                <h1 className="h1-product text-start active">
                  <span itemprop="name">{appData.appTitle}</span>
                </h1>
                <h2 className="h2-product" itemprop="description"></h2>
                <div className="description" itemprop="description">
                  <div
                    className="desc text-start"
                    dangerouslySetInnerHTML={{
                      __html: appData.appDescription,
                    }}
                  ></div>
                </div>
                <br />
              </div>
            </div>
          </div>

          <div className="wrapper-bg ">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h3>
                    Direct Link{" "}
                    <Link to={appData.appLink} className="libelle-lien active">
                      {appData.appTitle}
                    </Link>
                  </h3>
                  <div className="app-logo">
                    <Link to={appData.appStore}>
                      <img
                        src={`${window.location.origin}/img/staticPic/appS.png`}
                        alt="Download GO TERRAIN from Google Play or the App Store"
                        className="img-fluid"
                      />
                    </Link>
                    <Link to={appData.googlePlay}>
                      <img
                        src={`${window.location.origin}/img/staticPic/playS.png`}
                        alt="Download GO TERRAIN from Google Play or the App Store"
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper wrapper-specs">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-xl-9 mt-5">
                      <div
                        className="wrapper"
                        dangerouslySetInnerHTML={{
                          __html: appData.appFeatures,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default DownloadApp;
