import React, { useEffect, useState } from "react";

import "./Footer.css";
import { Link } from "react-router-dom";
import apiRequests from "../../services/config";

export default function Footer() {
  const [footerData, setFooterData] = useState({});

  const getSettingsPage = async () => {
    await apiRequests("/get-setting").then((res) => {
      setFooterData({
        googlePlay: res.data.data.googleplay_link,
        playStore: res.data.data.appstore_link,
        telegram: res.data.data.telegram,
        whatsapp: res.data.data.whatsapp,
        instagram: res.data.data.instagram,
      });
    });
  };

  useEffect(() => {
    getSettingsPage();
  }, []);

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-xl-3">
            <p className="footer">Metal Detectors </p>
            <ul className="links">
              <li>
                <Link to={"/where-to-buy"}>Where to buy?</Link>
              </li>
              <li>
                <Link to={"/contact-us"}>Contact Us</Link>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-4 col-xl-2">
            <p className="footer">Support </p>
            <ul className="links">
              <li>
                <Link to={"/user-manuals"}>User manuals </Link>
              </li>
              <li>
                <Link to={"/faq"}>F.A.Q. </Link>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-4 col-xl-3">
            <p className="footer">Related products </p>
            <ul className="links">
              <li>
                <Link to={footerData.googlePlay} className="android social">
                  Go to Google Play
                </Link>
                <Link to={footerData.playStore} className="apple social">
                  Go to Apple Store
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-12 col-xl-4">
            <div className="footer-nl">
              <label className="footer">Newsletter </label>
              <form action="#" method="post" className="nl-form">
                <input
                  type="email"
                  id="nl-email"
                  name="email"
                  className="form-control"
                  placeholder="Enter your email address"
                />
                <input type="submit" id="nl-send" name="send" value="OK" />
              </form>
            </div>

            <div className="footer-rs">
              <p className="d-none d-sm-inline-block">Follow us </p>
              <ul>
                <li>
                  <Link
                    to={footerData.instagram}
                    target="_blank"
                    rel="noopener"
                    className="instagram"
                  >
                    <i class="bi bi-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to={footerData.telegram}
                    target="_blank"
                    rel="noopener"
                    className="instagram"
                  >
                    <i class="bi bi-telegram"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to={footerData.whatsapp}
                    target="_blank"
                    rel="noopener"
                    className="instagram"
                  >
                    <i class="bi bi-whatsapp"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
