import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import Header from "./../../components/header/Header";
import Footer from "./../../components/footer/Footer";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";

import "./SpesiaalProduct.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import Loader from "../../components/loader/Loader";

export default function SpesiaalProduct() {
  const { productName } = useParams();
  const [images, setImages] = useState([]);
  const [imagesRow, setImagesRow] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [specifications, setSpecifications] = useState("");
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    fetch(`https://meta-detector.com/v1/api/product/${productName}`)
      .then((data) => data.json())
      .then((result) => {
        console.log(result);
        let urlImages = [];
        let urlImagesRow = [];

        setTitle(result.data.title);
        setDescription(result.data.description);
        setSpecifications(result.data.specifications);

        result.data.product_image.forEach((img) => {
          let urlImg = { img: img.file.url };
          urlImages.push(urlImg);
        });
        result.data.product_row_image.forEach((img) => {
          let urlImg = { img: img.file.url };
          urlImagesRow.push(urlImg);
        });
        setImages(urlImages);
        setImagesRow(urlImagesRow);

        setIsLoader(false);
      });
  }, [productName]);

  if (isLoader) {
    return <Loader loaderStyle={isLoader ? "flex" : "none"} />;
  }

  return (
    <>
      <Header />
      <main id="content" style={{ paddingBottom: "0" }}>
        <div itemScope="" itemType="http://schema.org/Product">
          <div className="container-fluid">
            <Breadcrumb links={[{ id: 3, title: title }]} />

            <div className="row mb-5 align-items-lg-center margin-top-20">
              <div className="col-12 col-md-12 col-lg-6">
                <Swiper
                  modules={[Pagination]}
                  spaceBetween={10}
                  pagination={{ clickable: true }}
                  className="mySwiper2"
                >
                  {images.map((img, index) => (
                    <SwiperSlide key={index}>
                      <img
                        src={`${window.location.origin}${img.img}`}
                        itemScope="image"
                        className="img-fluid swiper-lazy swiper-lazy-loaded"
                        alt={`${title}`}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <h1 className="h1-product active">
                  <span itemScope="name">{title}</span>
                </h1>
                <p className="h2-product" itemScope="description">
                  <div
                    className="wrapper"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                </p>
                <br />
                <Link
                  to="/where-to-buy"
                  className="bouton btn-rouge bouton_CTA_OU_ACHETER"
                >
                  WHERE TO BUY ?
                </Link>
              </div>
            </div>

            {imagesRow.map((imgUrl, index) => (
              <div
                key={index}
                className="row sections"
                style={{ padding: "1rem 0" }}
              >
                <div className="col-lg-12 zone_telecommande">
                  <img
                    src={`${window.location.origin}${imgUrl.img}`}
                    alt=""
                    className="image_fluide"
                  />
                </div>
              </div>
            ))}
          </div>

          {/*  */}
          <div className="container mt-5 specifications">
            <div className="row">
              <div className="col-12 col-xl-3">
                <h3>Specifications </h3>
              </div>
              <div className="col-12 col-xl-9">
                <div
                  className="wrapper"
                  dangerouslySetInnerHTML={{ __html: specifications }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
