import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import apiRequests from "../../../services/config";
import Loader from "../../../components/loader/Loader";
import Editor from "../../../components/adminPanel/form/Editor";

export default function Faq() {
  const [faqLists, setFaqLists] = useState([]);
  const [faqGroup, setFaqGroup] = useState([]);
  const [faqID, setFaqID] = useState(0);
  const [faqGroupID, setFaqGroupID] = useState(0);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isvalid, setIsvalid] = useState(false);

  const faqGroupLists = async () => {
    await apiRequests("/faq-groups/list").then((result) => {
      setFaqGroup(result.data.data);
    });
  };

  const getFaqLists = async () => {
    await apiRequests("/faqs/list").then((result) => {
      setFaqLists(result.data.data);
    });
  };

  const createFaq = async () => {
    setIsLoader(true);

    let newFaq = {
      question: question,
      answer: answer,
      faq_group_id: faqGroupID,
    };

    await apiRequests.post("/faqs/create", newFaq).then((result) => {
      swal({
        title: "اطلاعات با موفقیت ثبت شد.",
        icon: "success",
        buttons: "حله",
      });
      getFaqLists();
      setFaqGroupID(0);
      setQuestion("");
      setAnswer("");
      setIsLoader(false);
    });
  };

  const deleteFaq = async (faqID) => {
    swal({
      title: "آیا از حذف اطلاعات اطمینان دارید؟",
      icon: "warning",
      buttons: ["نه", "آره"],
    }).then(async (result) => {
      if (result) {
        setIsLoader(true);

        await apiRequests(`/faqs/delete/${faqID}`);
        swal({
          title: "اطلاعات با موفقیت حذف شد.",
          icon: "success",
          buttons: "حله",
        });
        getFaqLists();
        setIsLoader(false);
      }
    });
  };

  const editFaq = (faq) => {
    setIsUpdate(true);
    setFaqID(faq.id);
    setAnswer(faq.answer);
    setFaqGroupID(faq.faq_group_id);
    setQuestion(faq.question);
    setIsvalid(false);
  };

  const updateFaq = async () => {
    setIsLoader(true);
    let updateFaqObj = {
      question: question,
      answer: answer,
      faq_group_id: faqGroupID,
    };
    console.log(updateFaqObj);

    await apiRequests
      .post(`/faqs/update/${faqID}`, updateFaqObj)
      .then((result) => {
        swal({
          title: "اطلاعات با موفقیت ویرایش شد.",
          icon: "success",
          buttons: "حله",
        });
        getFaqLists();
        setFaqGroupID(0);
        setQuestion("");
        setAnswer("");
        setIsLoader(false);
        setIsUpdate(false);
      });
  };

  const cancelChange = () => {
    setFaqGroupID(0);
    setQuestion("");
    setAnswer("");
  };

  useEffect(() => {
    faqGroupLists();
    getFaqLists();
  }, []);

  useEffect(() => {
    if (faqGroupID && question && answer) {
      if (!isUpdate) {
        setIsvalid(true);
      }
    } else {
      setIsvalid(false);
      setIsUpdate(false);
    }
  }, [faqGroupID, question, answer]);

  return (
    <div dir="rtl">
      <Loader loaderStyle={isLoader ? "flex" : "none"} />

      <h1 className="fw-bold">FAQ</h1>
      <div className="row justify-content-start shadow rounded-4 my-4 py-3 ">
        <div className="col-md-5">
          <div>
            <form className="p-2">
              <div className="row">
                <div className="mb-4">
                  <h4 className="my-3 feature-title">سوال</h4>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    value={question}
                    onChange={(event) => setQuestion(event.target.value)}
                    required=""
                  />
                </div>
                <div className="form-group">
                  <label for="exampleSelect" className="form-label">
                    انتخاب گروه
                  </label>
                  <select
                    name="select"
                    id="exampleSelect"
                    className="form-control"
                    value={faqGroupID}
                    onChange={(event) => setFaqGroupID(event.target.value)}
                  >
                    <option value={-1}>... انتخاب کنید</option>
                    {faqGroup.map((faqGroup) => (
                      <option key={faqGroup.id} value={faqGroup.id}>
                        {faqGroup.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="col-12">
          <h4 className="my-3 feature-title">جواب</h4>
          <Editor value={answer} setValue={setAnswer} />

          {isvalid ? (
            <button
              className="btn btn-primary btn-color my-4 ms-3"
              type="submit"
              onClick={createFaq}
            >
              ایجاد
            </button>
          ) : (
            <button
              disabled
              className="btn btn-primary btn-color my-4 ms-3"
              type="submit"
              onClick={createFaq}
            >
              ایجاد
            </button>
          )}

          {isUpdate ? (
            <button
              className="btn btn-primary btn-color my-4"
              type="submit"
              onClick={updateFaq}
            >
              بروزرسانی
            </button>
          ) : (
            <button
              disabled
              className="btn btn-primary btn-color my-4"
              type="submit"
            >
              بروزرسانی
            </button>
          )}

          <button
            className="btn btn-danger btn-color my-4 me-3"
            onClick={cancelChange}
          >
            کنسل
          </button>
        </div>
      </div>

      <div className="row mt-3">
        <table className="table table-dark">
          <thead>
            <tr>
              <th>#</th>
              <th>عنوان</th>
              <th className="w-50px ">اکشن</th>
            </tr>
          </thead>
          <tbody>
            {faqLists.map((faq, index) => (
              <tr key={faq.id}>
                <th scope="row">{index + 1}</th>
                <td>{faq.question}</td>
                <td className="text-center">
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteFaq(faq.id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                  </button>

                  <button class="btn btn-success" onClick={() => editFaq(faq)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
